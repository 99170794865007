import { useState } from "react";
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_SECTION,
} from "gql/sections/sections.query";

export const PER_PAGE = 10;

const useAddSectionService = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newSection, setNewSection] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [createSectionMutation, { loading: createSectionLoading, error: createSectionError }] = useMutation(CREATE_SECTION);

  const addSection = async (

    { sectionName, forStartup, coursesIncluded }: { sectionName: string, forStartup: boolean, coursesIncluded: string[] }
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await createSectionMutation({
      variables: {
        input: {
          name: sectionName,
          forStartup,
          courses: coursesIncluded,
        }
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === `Section, ${sectionName}, Already Exists`) {
        message = `${t('general.section')} ${t('messages.already_registered')}`
      }
      variant = 'error'
      setError(message)
    } else {
      setNewSection(data.addSection)
      message = `${t('general.section')} ${t('messages.added')}`
      variant = 'success'
    }
    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addSection,
    addSectionLoading: loading,
    newSection,
    error,
  }
};

export default useAddSectionService;
