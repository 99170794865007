import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import { useSwal } from 'hooks/useSwal'
import { useAction } from 'store/actions'
import { useData } from 'context/DataContext'
import { CLEAR_FORMDATA } from 'store/types'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { useTranslation } from 'react-i18next'
import { genderOptions } from 'components/UsersLayout/staticData'
import { checkValues } from '../checkCoachValues'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Loader from 'components/common/Loader'
import { CoachSchema } from 'helpers/validationSchemas'
import { useUserValue } from 'context/UserContext'
import useAddUserServie from 'hooks/users/useAddUserService'
import TextInput from 'components/common/TextInput/TextInput'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { Button } from 'components/common/Button'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/core'

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData: any
  onClose?: any
}

interface FormData {
  firstName: string
  lastName: string
  email: string
  phone: string
  gender: any
  birthDate: any
  biography: string
  note: string
  requestPasswordChange: boolean
  role: string
  courses: any
  jobTitle: string
  location: string
  phoneFields: any
  phoneFieldValue: any
  avatar: any
}

const formData: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  birthDate: '',
  biography: '',
  note: '',
  requestPasswordChange: false,
  role: '',
  courses: null,
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
  avatar: '',
}

const AddCoach = ({ title, role, drawerName, drawerData, onClose }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const ref: any = useRef()
  const [dataState, dispatchData] = useData()
  const { toggleDrawerConfirm } = useAction()
  const { fireSwal } = useSwal()
  const { addUser, user, error, addUserLoading } = useAddUserServie({
    role: 'COACH',
    companyId: state.selectedCompanyId,
    filter: {},
  })

  const [fetchCourse, { data, loading }] = useLazyQuery(GET_ALL_COURSE)

  const query: any = {}

  query.companyId = {
    type: 'exact',
    value: state.selectedCompanyId,
  }

  if (state.currentUser.companyId || state.selectedCompanyId) {
    query.isBought = {
      type: 'bool',
      value: 'false',
    }
  }

  // const { data: courses } = useQuery(GET_ALL_COURSE, {
  //   variables: {
  //     filter: { ...query }
  //   }
  // })

  const [generalError, setGeneralError] = useState('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: CoachSchema,
    onSubmit(values) {
      if (addUserLoading) return

      const data: any = { ...values }

      data.role = role
      delete data.phoneFieldValue


      if (data.courses) {
        data.courses = data.courses.map((i: any) => ({
          name: i.label,
          courseId: i.value,
        }))
      } else {
        data.courses = []
      }

      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      const companyId = drawerData.companyId || state.selectedCompanyId
      addUser(data, role, companyId, onSuccess)
    },
  })

  useEffect(() => {
    setFieldValue(
      'requestPasswordChange',
      state.currentUser.settings.askPassword,
    )
  }, [])

  const onSuccess = () => {
    closeDrawer()
    dispatchData({ type: CLEAR_FORMDATA })
    drawerData.onSuccess()
  }

  const closeDrawer = () => onClose()
  const handleCloseDrawer = () => {
    const isEmpty = checkValues(values)
    if (isEmpty) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => { },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (user) {
      closeDrawer()
      toggleDrawerConfirm(false, '')
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchCourse({
        variables: {
          filter: {
            name: {
              type: 'match',
              value,
            },
            ...query,
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const theme = useTheme();

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                {/* <SelectField
                  placeholder={t('user_details.gender')}
                  options={genderOptions}
                  error={errors.gender && touched.gender ? true : false}
                  errorMessage={errors.gender ? errors.gender : ''}
                  touched={touched.gender}
                  value={values.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                  isClearable
                /> */}
                <GenderPicker
                  checked={values.gender}
                  onChange={(gender: string) => {
                    setFieldValue('gender', gender)
                  }} />
              </FormField>
              <FormField width="48%">
                <DatePicker
                  label={t('user_details.birthday')}
                  touched={touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  name="birthDate"
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>
              {!drawerData.fromCourse && (
                <FormField>
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: any) => setFieldValue('courses', e)}
                    data={(data && data.getAllCourse.data) || []}
                    value={values.courses}
                    label={t('form_fields.type_to_search_courses')}
                    loading={loading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormField>
              )}
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddCoach
