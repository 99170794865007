import { useState } from "react";
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { useMutation } from "@apollo/react-hooks";

import {
  EDIT_SECTION
} from "gql/sections/sections.query";

const useEditSection = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [editedSection, setEditedSection] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [editSectionMutation, { loading: editSectionLoading, error: editSectionError }] = useMutation(EDIT_SECTION);

  const editSection = async (
    { sectionName, sectionId, forStartup, coursesIncluded }: { sectionName: string, sectionId: string, forStartup: boolean, coursesIncluded: string[] }
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await editSectionMutation({
      variables: {
        id: sectionId,
        input: {
          name: sectionName,
          forStartup: forStartup,
          courses: coursesIncluded,
        }
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === `Section, ${sectionName}, Already Exists`) {
        message = `${t('general.section')} ${t('messages.already_registered')}`
      }
      variant = 'error'
      setError(message)
    } else {
      setEditedSection(data.editSection)
      message = t('messages.success')
      variant = 'success'
    }
    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    editSection,
    editSectionLoading: loading,
    editedSection,
    error,
  }
};


export default useEditSection;
