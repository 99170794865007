import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Checkbox from 'components/common/Checkbox'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AvatarIcon from '../../components/common/AvatarInput/profile.svg'
import { mainColor } from 'components/common/Colors/CommonColors'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import { GroupItem, GroupTitle } from 'components/UsersLayout/gridConfig'
import { IconButton } from 'material-ui'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import { Tooltip } from 'recharts'
import strCutter from 'utils/strCutter'
import CompanyServiceToggle from './CompanyDetails/CompanyServiceToggle'
import { useTheme } from '@material-ui/core'

interface itemProps {
  id: string
  isChecked: boolean
  name: string
  companyEmail: string
  address: string
  phone: string
  companyActivity: any
  status: string
  createDate: string
  coverImage: null
  internalCoursesCount: string
  platformCoursesCount: string
  studentsCount: string
  platformService: any
}

// export const columnConfig = (handelClick: any, selectItem: any) => {
//   const grid = [
//     {
//       id: 'isChecked',
//       render: (item: itemProps) => (
//         <Checkbox
//           checked={item.isChecked}
//           onChange={() => selectItem(item.id)}
//         />
//       ),
//       numeric: false,
//       disablePadding: true,
//     },
//     {
//       id: 'name',
//       numeric: false,
//       disablePadding: true,
//       label: 'Name',
//       render: (item: itemProps) => (
//         <div
//           onClick={() => handelClick(item.name, item.id)}
//           style={{ cursor: 'pointer' }}
//         >
//           {item.name}
//         </div>
//       ),
//     },
//     {
//       id: 'companyEmail',
//       numeric: false,
//       disablePadding: false,
//       label: 'Email',
//       render: (item: itemProps) => <div>{item.companyEmail}</div>,
//     },
//     {
//       id: 'address',
//       numeric: false,
//       disablePadding: false,
//       label: 'Address',
//       render: (item: itemProps) => <div>{item.address}</div>,
//     },
//     {
//       id: 'phone',
//       numeric: false,
//       disablePadding: false,
//       label: 'Phone',
//       render: (item: itemProps) => (
//         <div style={{ padding: '0 10px 0 0' }}> {item.phone}</div>
//       ),
//     },
//     {
//       id: 'companyActivity',
//       numeric: false,
//       disablePadding: false,
//       label: 'Activity',
//       render: (item: itemProps) => {
//         if (item.companyActivity && item.companyActivity.length > 0) {
//           return <div>{item.companyActivity[0].label}</div>
//         }
//       },
//     },
//     {
//       id: 'createDate',
//       numeric: false,
//       disablePadding: false,
//       label: 'Created',
//       render: (item: itemProps) => (
//         <div style={{ padding: '0 10px' }}>
//           {moment(parseInt(item.createDate)).format('DD.MM.YYYY')}
//         </div>
//       ),
//     },
//     {
//       id: 'status',
//       numeric: false,
//       disablePadding: false,
//       label: 'Status',
//       render: (item: itemProps) => (
//         <StatusWrapper
//           className={`${item.status === 'ACTIVE' ? 'active' : 'suspended'}`}
//         >
//           <StatusCircle></StatusCircle>
//           {item.status}
//         </StatusWrapper>
//       ),
//     },
//   ]

//   return grid
// }

export const columnConfig = (
  role: string,
  handelClick: any,
  selectItem: any,
  t?: any,
  theme?: any
) => {

  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => {
              selectItem(item.id);

            }}
          />
          <img
            onClick={() => handelClick(item.name, item.id, item.status)}
            src={item.coverImage || '/svg/companyAvatar.svg'}
            alt="Avatar"
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'companyName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.companyName')}`,
      render: (item: itemProps) => (
        <div
          onClick={() => handelClick(item.name, item.id, item.status)}
          style={{ cursor: 'pointer' }}
        >
          {item.name}
        </div>
      ),
    },
    {
      id: 'courses',
      label: t('company_card.company_course'),
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.internalCoursesCount}</CountContainer>
      ),
    },
    {
      id: 'wenrollCourses',
      label: t('company_card.wenroll_course'),
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.platformCoursesCount}</CountContainer>
      ),
    },
    {
      id: 'studentsCourses',
      label: t('company_card.student'),
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.studentsCount}</CountContainer>
      ),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status.toLowerCase()}`}>
          {item.status}
        </StatusWrapper>
      ),
    },
    {
      id: 'service',
      label: t('companies_layout.platform_service'),
      render: (item: itemProps) => (
        <CompanyServiceToggle
          id={item?.id}
          platformService={item?.platformService}
          name={item?.name}
        />
      ),
    },
  ]

  return grid
}

export const actionConfig = (
  handleEditClick: any = null,
  handleDeleteClick: any,
  handleChangeStatusClick?: any,
  handleSwitchCompanyClick?: any,
  permission?: any,
  t?: any,
  isWenrollUser?: boolean,
) => {
  const action = [
    // {
    //   hide: !permission.switchToCompanyMode,
    //   render: (item: any) => (
    //     <Action onClick={() => handleSwitchCompanyClick(item.name, item.id)}>
    //       <>
    //         <ViewCompactIcon />
    //         <span>View</span>
    //       </>
    //     </Action>
    //   )
    // },

    {
      // hide: !permission.,
      render: (item: any) => {
        if (handleEditClick) {
          return <Action onClick={() => handleEditClick(item.id, item.status)}>
            <EditRoundedIcon />
            <span>{t('actions.edit')}</span>
          </Action>
        }
        else
          return null
      },
    },
    {
      hide: !permission.deleteCompany,
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick(item.id)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },

    {
      hide: !(isWenrollUser === true),
      render: (item: any) => (
        <Action onClick={() => handleChangeStatusClick(item.id, item.status)}>
          {item.status === 'ACTIVE' ? (
            <>
              <BackspaceRoundedIcon />
              <span>{t('actions.deactivation')}</span>
            </>
          ) : (
            <>
              <CheckCircleIcon />
              <span>{t('actions.activation')}</span>
            </>
          )}
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  padding-bottom: 2px;
  border-radius: 4px;

  &.active {
    color: #269f5a;
    background: #269f5a1f;
  }

  &.suspended {
    color: #cc3131;
    background: #cc31311f;
  }

  &.pending {
    color: #ffa502;
    background: #ffa5021f;
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.151);
  }
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};;
`
